<template>
  <div>
    <div class="row justify-content-md-center" v-cloak v-if="!state.loaded">
      <div class="col col-lg-2"></div>
      <div class="col-md-auto mt-20">
        <rotate-square5></rotate-square5>
      </div>
      <div class="col col-lg-2"></div>
    </div>
    <div class="card" v-else>
      <div class="card-body">
        <!-- <form action="#" @submit.prevent="submit"> -->
        <form action="#" @submit.prevent="showModal({ type: 'submit' })">
          <div class="form-body">
            <h3 class="card-title">Konfirmasi</h3>
            <hr />

            <div class="form-body">
              <h3 class="box-title">Konfirmasi Surat</h3>
              <hr class="m-t-0 m-b-40" />

              <div class="row">
                <div class="col-md-8">
                  <div class="form-group row">
                    <label
                      for="error"
                      class="control-label text-right col-md-3"
                    >
                      Konfirmasi
                      <small style="color: red">*</small>
                    </label>

                    <div class="col-md-9 col-xs-9">
                      <!-- <div class="col-md-6">
                                    <input
                                        type="radio"
                                        checked="checked"
                                        id="confirm"
                                        name="confirm"
                                        value="1"
                                        v-model="payload.confirm"
                                    />
                                    <label for="confirm">Konfirmasi</label>&nbsp;
                                </div> -->
                      <div class="col-md-6">
                        <input
                          type="radio"
                          :checked="false"
                          id="retur"
                          name="retur"
                          value="0"
                          v-model="payload.confirm"
                        />
                        <label for="retur">Retur</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              <div class="row">
                <div class="col-md-12">
                  <wysiwyg v-model="myHTML" />
                </div>
              </div>
            </div>
          </div>

          <hr class="m-t-40" />

          <div class="form-actions">
            <!-- <button 
            type="submit" 
            class="btn btn-info" 
            @click="setApproval(0)"
            title="Simpan"
            >
              <i class="fa fa-check"></i>
              {{$t('save')}}
            </button> &nbsp;
            
            <button
              type="submit"
              class="btn btn-success"
              @click="setApproval(1)"
              title="Kirim"
            >
              <i class="mdi mdi-send"></i>
              Kirim
            </button> &nbsp; -->
            <button
              type="button"
              @click="showModal({ type: 'submit' })"
              class="btn btn-success"
              title="Submit"
            >
              Submit</button
            >&nbsp;
            <button
              type="button"
              @click="showModal({ type: 'cancel' })"
              class="btn btn-inverse"
              title="Cancel"
            >
              Cancel
            </button>
          </div>
        </form>
        <ModalDefault
          v-show="isVisible"
          :title="modalTitle"
          :content="modalContent"
          :action="ActionChangeable"
          :confirmation="isConfirmation"
          @close="onClose"
        />
      </div>
    </div>
    <!-- <Modal v-show="isModalVisible" @close="closeModal" @action="onDelete(deleteData)"/> -->

    <hr />
    <div class="card">
      <h4>Keterangan</h4>
      <table>
        <tr>
          <td>Konfirmasi</td>
          <td>Untuk mengkonfirmasi surat</td>
        </tr>
        <tr>
          <td colspan="2">&nbsp;</td>
        </tr>
        <tr>
          <td>Retur</td>
          <td>Untuk meretur surat</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { RotateSquare5 } from "vue-loading-spinner";
import Input from "@/components/input/input";
import Multiselect from "vue-multiselect";
import Upload from "@/components/input/upload.vue";
import axios from "axios";
import Datepicker from "vuejs-datepicker";
import Modal from "@/components/modal/modal.vue";
import store from "@/store";
import moment from "moment";

export default {
  components: {
    Modal,
    Input,
    Datepicker,
    RotateSquare5,
    Upload,
    Multiselect,
  },
  computed: {
    editMode() {
      return !!this.$route.params.id;
    },
    state() {
      return this.$store.state.documentIn;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
  },
  data() {
    const uploader = this.fineUploader(this, "document_in");

    return {
      uploader,
      options: [
        "Select option",
        "Disable me!",
        "Reset me!",
        "Admin Dinas Komunikasi dan Informatika ( Kepala Seksi Dinas Komunikasi dan Informatika )",
        "label",
        "searchable",
      ],
      disabledDates: {
        to: new Date(Date.now()),
      },
      isModalVisible: false,
      payload: {
        confirm: 1,
        myHtml: "",
      },
      myHTML: "",
      isDisabled: false,
      isModalVisible: false,
      isVisible: false,
      modalTitle: "",
      modalContent: "",
      isConfirmation: false,
      ActionChangeable: null,
      disabledButton: false,
    };
  },
  async mounted() {
    this.initialize();
    this.getAllReference();
    const user = JSON.parse(localStorage.getItem("user"));
    if (!this.editMode) {
      this.payload.unitkerja = [{ value: user.unit_id, text: user.unit }];
    }

    console.log(this.payload.tanggal_surat);
  },

  watch: {
    $route: function (search) {
      if (this.editMode) {
        axios.get(`/document_in/${this.$route.params.id}`).then((response) => {
          const state = {
            loaded: true,
          };
          this.$store.commit("documentIn/STATE", state);
          this.edit(response.data.data);
          this.isModalVisible = false;
        });
      }
    },
  },

  methods: {
    onClose() {
      this.isVisible = false;
    },
    async ambilTanggal() {
      this.disabledDates.to = this.payload.tanggal_surat;
    },
    showModal(option) {
      this.isVisible = true;
      if (option.type == "submit") {
        this.modalTitle = "Simpan Data?";
        this.modalContent = "<p>Apakah anda yakin ingin menyimpan data?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.submit;

        // if(option.data == 0){
        //   this.modalTitle = "Simpan Data?";
        //   this.modalContent = "<p>Apakah anda yakin ingin menyimpan surat?</p>";
        //   this.isConfirmation = true;
        //   this.ActionChangeable = this.submit;
        // }else if(option.data == 1){
        //   this.modalTitle = "Kirim Data?";
        //   this.modalContent = "<p>Apakah anda yakin ingin mengirim surat?</p>";
        //   this.isConfirmation = true;
        //   this.ActionChangeable = this.submit;
        // }
      } else if (option.type == "cancel") {
        this.modalTitle = "Kembali?";
        this.modalContent = "<p>Apakah anda yakin ingin kembali?</p>";
        this.isConfirmation = true;
        this.ActionChangeable = this.goBack;
      }
    },
    // setApproval(value) {
    //   if (this.disabledButton) {
    //     return;
    //   }
    //   this.disabledButton = true;
    //   setTimeout(() => (this.disabledButton = false), 1000);
    //   this.payload.persetujuan = value;
    // },
    // getAllReference() {
    //   var query = "";
    //   this.sifatSurat(query);
    //   this.kecepatanSurat(query);
    //   this.jenisLampiran(query);
    //   this.jenisSurat(query);
    //   this.tipeAsalSurat(query);
    //   this.kodeSurat(query);
    //   this.keteranganSurat(query);
    //   this.redaksi(query);
    //   this.unitKerja(query);
    // },
    async initialize() {
      try {
        if (this.editMode) {
          axios
            .get(`/document_in/${this.$route.params.id}`)
            .then((response) => {
              const state = {
                loaded: true,
              };
              this.$store.commit("documentIn/STATE", state);
              this.edit(response.data.data);
            });
        } else {
          this.$store.dispatch("documentIn/onAdd");
          axios.get(`/document_in/new`).then((response) => {
            this.payload.agenda = response.data.data.agenda_number;
            this.payload.tanggal_surat = new Date();
            this.payload.tanggal_diterima = new Date();
          });
        }
      } catch (e) {
        this.handleApiError(e);
        this.error = e.message;
      }
    },
    edit(data) {
      //   this.payload = {
      //     unitkerja: data.receiver,
      //     agenda: data.agenda_number,
      //     tanggal_surat: new Date(data.tanggal_surat),
      //     tanggal_diterima: new Date(data.tanggal_diterima),
      //     sifat: data.document_sifat,
      //     jenis: data.document_jenis,
      //     perihal: data.subject,
      //     nama: data.document_name,
      //     nomorsurat: data.nomorsurat,
      //     pages: data.pages,
      //     myHTML: data.description,
      //     files: data.files,
      //     jumlah: data.jumlah,
      //     lampiran: data.document_lampiran,
      //     asrat: data.document_asrat,
      //     kodrat: data.document_kodrat,
      //     tas: data.document_tas,
      //     kecrat: data.document_kecrat,
      //     ketrat: data.document_ketrat,
      //     redaksi: data.document_redaksi
      //   };
      //   this.files = data.files;
      //   this.dropdownSifatSurat.value = data.document_asrat;
      //   this.dropdownKecepatanSurat.value = data.document_kecrat;
      //   this.dropdownLampiran.value = data.document_lampiran;
      //   this.dropdownJenisSurat.value = data.document_jenis;
      //   this.dropdownTipeAsalSurat.value = data.document_tas;
      //   this.dropdownAsalSurat.value = data.document_asrat;
      //   this.dropdownKodeSurat.value = data.document_kodrat;
      //   this.dropdownKeteranganSurat.value = data.document_ketrat;
      //   this.dropdownRedaksi.value = data.document_redaksi;
      //   this.dropdownUnitKerja.value = data.receiver;
      //   this.myHTML = data.description;
    },
    goBack() {
      this.$router.push("/documentIn");
    },
    closeModal() {
      this.isModalVisible = false;
    },
    onDelete() {
      this.isModalVisible = false;
    },
    openModal() {
      this.isModalVisible = true;
    },
    submit() {
      //   if (
      //     this.dropdownSifatSurat.value.length === 0 &&
      //     this.dropdownKecepatanSurat.value.length === 0 &&
      //     this.dropdownLampiran.value.length === 0 &&
      //     this.dropdownJenisSurat.value.length === 0 &&
      //     this.dropdownTipeAsalSurat.value.length === 0 &&
      //     this.dropdownAsalSurat.value.length === 0 &&
      //     this.dropdownKodeSurat.value.length === 0 &&
      //     this.dropdownKeteranganSurat.value.length === 0 &&
      //     this.dropdownRedaksi.value.length === 0 &&
      //     this.dropdownUnitKerja.value.length === 0
      //   ) {
      //     this.dropdownSifatSurat.isTouched = true;
      //     this.dropdownKecepatanSurat.isTouched = true;
      //     this.dropdownLampiran.isTouched = true;
      //     this.dropdownJenisSurat.isTouched = true;
      //     this.dropdownTipeAsalSurat.isTouched = true;
      //     this.dropdownAsalSurat.isTouched = true;
      //     this.dropdownKodeSurat.isTouched = true;
      //     this.dropdownKeteranganSurat.isTouched = true;
      //     this.dropdownRedaksi.isTouched = true;
      //     this.dropdownUnitKerja.isTouched = true;
      //     window.scrollTo(0, 0);
      //   }
      //   if (this.payload.file) {
      //     this.myFile = this.payload.file.split(",");
      //   }
      const payload = {
        confirm: this.payload.confirm,
        description: this.myHTML,
      };
      const data = JSON.stringify(payload);
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.editMode) {
            this.$store.dispatch("documentIn/submitConfirm", {
              data,
              id: this.$route.params.id,
            });
          } else {
            this.$store.dispatch("documentIn/submitConfirm", {
              data,
              id: this.$route.params.id,
            });
          }
        }
      });
    },
    clearAll() {
      this.payload.kepada = [];
    },
    // asyncFind(query) {
    //   this.dropdownTujuan.isLoadingUserFind = true;
    //   axios
    //     .post(`users/findUserTo`, { search: query })
    //     .then(res => {
    //       this.dropdownTujuan.userList = res.data.data;
    //       this.dropdownTujuan.isLoadingUserFind = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // sifatSurat(query) {
    //   this.dropdownSifatSurat.isLoadingSifatSurat = true;
    //   axios
    //     .get(`document_urgencies/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownSifatSurat.urgencyList = res.data.items;
    //       this.dropdownSifatSurat.isLoadingSifatSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // kecepatanSurat(query) {
    //   this.dropdownKecepatanSurat.isLoadingKecepatanSurat = true;
    //   axios
    //     .get(`document_speeds/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownKecepatanSurat.kecratList = res.data.items;
    //       this.dropdownKecepatanSurat.isLoadingKecepatanSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // jenisLampiran(query) {
    //   this.dropdownLampiran.isLoadingJenisLampiran = true;
    //   axios
    //     .get(`attachment_types/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownLampiran.lampiranList = res.data.items;
    //       this.dropdownLampiran.isLoadingJenisLampiran = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // jenisSurat(query) {
    //   this.dropdownJenisSurat.isLoadingJenisSurat = true;
    //   axios
    //     .get(`document_categories/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownJenisSurat.categoryList = res.data.items;
    //       this.dropdownJenisSurat.isLoadingJenisSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // tipeAsalSurat(query) {
    //   this.dropdownTipeAsalSurat.isLoadingTipeAsalSurat = true;
    //   axios
    //     .get(`typeSurat/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownTipeAsalSurat.tasList = res.data.items;
    //       this.dropdownTipeAsalSurat.isLoadingTipeAsalSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // asalSurat(query) {
    //   const mv = this;
    //   mv.dropdownAsalSurat.isLoadingAsalSurat = true;
    //   axios
    //     .get(
    //       `units/listByLevel?s=${query ? query : ""}&type_code=${
    //         mv.payload.tas.value
    //       }`
    //     )
    //     .then(res => {
    //       mv.dropdownAsalSurat.asratList = res.data.items;
    //       mv.dropdownAsalSurat.isLoadingAsalSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         mv.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // kodeSurat(query) {
    //   this.dropdownKodeSurat.isLoadingKodeSurat = true;
    //   axios
    //     .get(`mail_code/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownKodeSurat.kodratList = res.data.items;
    //       this.dropdownKodeSurat.isLoadingKodeSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // keteranganSurat(query) {
    //   this.dropdownKeteranganSurat.isLoadingKeteranganSurat = true;
    //   axios
    //     .get(`description_mail/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownKeteranganSurat.ketratList = res.data.items;
    //       this.dropdownKeteranganSurat.isLoadingKeteranganSurat = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // redaksi(query) {
    //   this.dropdownRedaksi.isLoadingRedaksi = true;
    //   axios
    //     .get(`redaksi/list?s=${query}`)
    //     .then(res => {
    //       this.dropdownRedaksi.redaksiList = res.data.items;
    //       this.dropdownRedaksi.isLoadingRedaksi = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // unitKerja(query) {
    //   this.dropdownUnitKerja.isLoadingUnitKerja = true;
    //   axios
    //     .get(`units/listIn?s=${query}`)
    //     .then(res => {
    //       this.dropdownUnitKerja.unitkerjaList = res.data.items;
    //       this.dropdownUnitKerja.isLoadingUnitKerja = false;
    //     })
    //     .catch(err => {
    //       if (err.response.status === 401) {
    //         this.$store.dispatch("auth/logout");
    //       }
    //     });
    // },
    // onTouchSifatSurat() {
    //   this.dropdownSifatSurat.isTouched = true;
    // },
    // onTouchKecepatanSurat() {
    //   this.dropdownKecepatanSurat.isTouched = true;
    // },
    // onTouchLampiran() {
    //   this.dropdownLampiran.isTouched = true;
    // },
    // onTouchJenisSurat() {
    //   this.dropdownJenisSurat.isTouched = true;
    // },
    // onTouchTipeAsalSurat() {
    //   this.dropdownTipeAsalSurat.isTouched = true;
    // },
    // onTouchKategoriTujuan() {
    //   this.dropdownKategoriTujuan.isTouched = true;
    // },
    // onTouchKategoriAsalSurat() {
    //   this.dropdownKategoriAsalSurat.isTouched = true;
    // },
    // onTouchAsalSurat() {
    //   this.dropdownAsalSurat.isTouched = true;
    // },
    // onTouchKodeSurat() {
    //   this.dropdownKodeSurat.isTouched = true;
    // },
    // onTouchKeteranganSurat() {
    //   this.dropdownKeteranganSurat.isTouched = true;
    // },
    // onTouchRedaksi() {
    //   this.dropdownRedaksi.isTouched = true;
    // },
    // onTouchUnitKerja() {
    //   this.dropdownUnitKerja.isTouched = true;
    // },
    // onChangeSifatSurat(value) {
    //   this.dropdownSifatSurat.value = value;
    //   if (value.value === 3) {
    //     this.payload.ketrat = {value: 2, text: "Tertutup"}
    //   } else {
    //     this.payload.ketrat = []
    //   }
    // },
    // onChangeKecepatanSurat(value) {
    //   this.dropdownKecepatanSurat.value = value;
    // },
    // onChangeLampiran(value) {
    //   this.dropdownLampiran.value = value;
    // },
    // onChangeJenisSurat(value) {
    //   this.dropdownJenisSurat.value = value;
    // },
    // onChangeTipeAsalSurat(value) {
    //   this.dropdownTipeAsalSurat.value = value;
    //   this.asalSurat();
    // },
    // onChangeAsalSurat(value) {
    //   this.dropdownAsalSurat.value = value;
    // },
    // onChangeKategoriAsalSurat(value) {
    //   this.dropdownKategoriAsalSurat.value = value;
    // },
    // onChangeKodeSurat(value) {
    //   this.dropdownKodeSurat.value = value;
    // },
    // onChangeKeteranganSurat(value) {
    //   this.dropdownKeteranganSurat.value = value;
    // },
    // onChangeRedaksi(value) {
    //   this.dropdownRedaksi.value = value;
    // },
    // onChangeUnitKerja(value) {
    //   this.dropdownUnitKerja.value = value;
    // },
    // nomorDokumen(value) {
    //   this.payload.nomor_dokumen = value.document_number;
    // }
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
.multiselect__tags {
  border: 1px solid #ced4da !important;
}
.invalid .multiselect__tags {
  border-color: #f04124 !important;
  padding: 5px 40px 0 8px !important;
}
.invalid .typo__label {
  color: #f04124;
  font-size: 0.8125rem;
}
.multiselect {
  color: #54667a !important;
}
.multiselect__select {
  height: 41px !important;
}
.normal-background {
  background-color: #fff !important;
}
</style>
<style lang="scss">
.vdp-datepicker {
  &.fullscreen-when-on-mobile {
    @media (max-width: 767px) {
      position: static;
      .vdp-datepicker__calendar {
        position: fixed;
        top: 50%;
        left: 5%;
        margin-top: -25%;
        width: 90%;
      }
    }
  }
}
</style>
